<template>
    <div class="layout--main" >


    <template >
      <the-navbar-vertical-inmobiliaria/>
    </template>
    <vx-card title="Opciones de crédito" class="vista-inmo">
          <div id="cotizacion">
            <div class="" v-if="items.length">
              <div class="row w-full">
                <br>
                <p class="text-grey mb-0" id="title">Ordenar por</p>
                <div class="col-md-12">
                  <vx-tooltip color="primary" :text="'Filtros'" >
                  <ul class="centerx vx-row">
                    <li v-for="filtro in filtros"  :key="filtro.value" style="margin:2%;">
                      <vs-radio v-model="filtroModel" :vs-value="filtro.value" @change="filtrar(filtro.value)">{{filtro.label}}</vs-radio>
                    </li>
                  </ul>
                  </vx-tooltip>
                </div>
              </div>
              <br>
              <div class="col-md-12">
                



                <div class="btn-fav" v-if="caso.favoritos.length==0">
                  <span class=" cursor-pointer"  @click="openFavs()" >Ver Favoritos ({{caso.favoritos.length}})</span>
                </div>   
                <div class="btn-fav" v-if="caso.favoritos.length>0">
                  <span class=" cursor-pointer"  @click="openFavs()" >Ver Favoritos ({{caso.favoritos.length}})</span>
                </div>              
              <div class="row w-full ocultar-celda"  style="background-color:#F1F4FF; border-radius:5px; padding:1.5em;">
                <div class="col-md-2 td-modal"></div>
                <div class="col-md-4 td-modal "></div>
                <div class="col-md-2 td-modal ocultar-celda ">Acciones</div>
                <div class="col-md-3 td-modal margen ocultar-celda">Productos</div>
              </div>  
                <div class="items-list-view" v-for="(item, index) in items" :key="item.objectID">
                    <Productos :item="item" :filtroName="filtroName" :filtroId="filtroId" class="" :user="caso.solicitante" :ingreso="ingreso">
                        <template slot="mejora" v-if="destino == 19 || destino == 25 || destino == 22 || destino == 33 || destino == 34">
                          <p class="text-md" style="color:green">Ahorro Mensual ${{numberFormat(item.MejoraMensualidad)}}</p>
                          <p class="text-md" style="color:green">Ahorro Total ${{numberFormat(item.AhorroTotal > 0 ? item.AhorroTotal : 0)}}</p>
                        </template>
                        <template slot="cofinavit" v-if="destino == 1 && caso.programa == 4 && item.GastosInicialesCofinavit">
                          <p class="item-description span-placeholder">Gastos Iniciales con Cofinanciamiento Infonavit ${{ numberFormat(item.GastosInicialesCofinavit) }}</p>
                        </template>
                        <template slot="gastos" v-else>
                          <p class="item-description span-placeholder">Gastos Iniciales ${{ numberFormat(item.GastosIniciales) }}</p>
                        </template>
                        <!-- SLOT: ACTION BUTTONS -->
                        <template slot="action-buttons" v-if="item.BancoId!=21">
                            <div class="item-view-secondary-action-btn rounded-lg flex flex-grow items-center justify-center text-white cursor-pointer">
                              <button class="favoritos" v-if="item.BancoId!=33" @click="solicitar(item)"><u>Visualizar producto</u></button> 
                            </div>
                        </template>

                        <template slot="action-buttons-2">
                          <!--<vx-tooltip text="Solicitud de crédito" position="top">
                            <vs-button color="primary" type="line" icon-pack="feather" icon="icon-file" @click="generaSolicitud(item)"></vs-button>
                          </vx-tooltip>-->
                          
                          <vx-tooltip text="Favoritos" >
                            <!--<vs-button color="primary" type="line" icon-pack="feather" @click.prevent='agregarFavorito(item)' v-if="comprobarFavorito(item)"><feather-icon icon="HeartIcon" :svgClasses="[{'text-danger fill-current' : !comprobarFavorito(item)}, 'h-4 w-4']" /></vs-button>
                            <vs-button color="primary" type="line" icon-pack="feather" @click.prevent='quitarFavorito(item)' v-else><feather-icon icon="HeartIcon" :svgClasses="[{'text-danger fill-current' : !comprobarFavorito(item)}, 'h-4 w-4']" /></vs-button>-->
                            <button class="favoritos"  icon-pack="feather" @click.prevent='agregarFavorito(item)' v-if="comprobarFavorito(item)">
                                 <svg class="heart">
                                  <path id="Vector" d="M19.855,0A7.63,7.63,0,0,0,13.75,3.066,7.634,7.634,0,0,0,0,7.686a14.683,14.683,0,0,0,.715,4.551C2.887,19.112,9.584,23.224,12.9,24.351a3,3,0,0,0,1.7,0c3.314-1.128,10.01-5.239,12.182-12.114A14.683,14.683,0,0,0,27.5,7.686,7.664,7.664,0,0,0,19.855,0Z" transform="translate(2.75 4.263)"/>
                                </svg>                             
                            </button>
                            <button class="favoritos" icon-pack="feather" @click.prevent='quitarFavorito(item)' v-else><feather-icon icon="HeartIcon" :svgClasses="[{'text-danger fill-current' : !comprobarFavorito(item)}, 'h-8 w-8']" /></button>
                          </vx-tooltip>
                        </template>
                    </Productos>
                     
                </div>
              </div>
               <vs-popup fullscreen title="Detalles del producto" :active.sync="popupSolicitud">
                <div class="vx-row justify-end" justify="flex-end">
                  <div class="vx-col lg:w-1/5 sm:w-full">
                    <vs-button color="success"  @click="sendCorreoElegido()">Enviar a correo</vs-button>
                  </div>
                  <div class="vx-col lg:w-1/5 sm:w-full">
                    <vs-button class="azulbtn" @click="descargarPDF()">Descargar PDF</vs-button>
                  </div>
                </div>
                <input class="vs-input hidden" hidden  v-model="s"  />
                <input class="vs-input hidden" hidden  v-model="o"  />
                <embed :src="''+urlSolicitud" type="application/pdf" width="100%" height="1000px" />
              </vs-popup>
            </div>
          </div>
        </vx-card>
        <div  class="modal fade " id="modalTabla"  ref="table" size="xl" hide-footer hide-header  >
      <div class="modal-dialog modal-lg modal-dialog-centered ">
        <div class="modal-content">
          <div class="modal-body modal-productos">
            <div class="vx-row justify-end" justify="flex-end">
                  <vs-button color="success" size="small" @click="sendCorreoElegido()">Enviar a correo</vs-button>
                  
                    <vs-button class="azulbtn" size="small" @click="descargarPDF()">Descargar PDF</vs-button>
                </div>
                <input class="vs-input hidden" hidden  v-model="s"  />
                <input class="vs-input hidden" hidden  v-model="o"  />
            <div class="row mb-4">
              <div class="d-md-flex justify-content-md-center"><img src="../../assets/images/pages/Logo_SOC_LAF.svg" alt="logo-soc"  style="margin: .5rem; height: 3.6rem; cursor:pointer;"/></div>
            </div>
            <div class="row">
              <div class="d-md-flex">
                <h6 class="titulo">Hola {{nombreSolicitante}}</h6>
              </div>
            </div>  
            <div class="row">
              <p class="descripcion-producto">Tu asesor inmobiliario {{activeUserInfo.Nombre}}, realizo una simulación con las siguientes condiciones:</p>
           
              <ul class="ul-list">
                <li class="descripcion-producto">Valor de la vivienda: ${{caso.valorInmueble}}</li>
                <li class="descripcion-producto">Monto de crédito: ${{caso.montoSolicitado}}</li>
                <li class="descripcion-producto">Enganche: ${{enganche}}</li>
              </ul>
           
              <p class="descripcion-producto">Trabajamos de la mano de un asesor hipotecario {{activeUserInfo.Nombre}} quien tiene alianza con las principales instituciones financieras de México, esto nos permite ayudarte a encontrar la mejor opción crediticia de acuerdo a tu perfil y necesidades.</p>
              <p class="d-md-flex justify-content-md-center"><img src="../../assets/images/pages/banner_bancos.jpg" class="banner-bancos"></p>
              <p class="descripcion-producto">Conoce un estimado de las condiciones de tu crédito</p>
            
              <ul class="ul-list">
                <li class="descripcion-producto">Banco seleccionado: {{bancoSeleccionadp}}</li>
                <li class="descripcion-producto">Mensualidad promedio:${{mensualidadPromedio}}</li>
                <li class="descripcion-producto">Tasa estimada: {{tasa}}%</li>
                <li class="descripcion-producto">Gastos notariales promedio: ${{gastosNotariales}}</li>
                <li class="descripcion-producto">Ingreso mínimo: ${{ingresoMinimo}}</li>
                <li class="descripcion-producto">CAT promedio: {{catPromedio}}%</li>
              </ul>
            
              <p class="descripcion-producto">¿Te gustaría recibir asesoría de un experto? Te acompañamos desde la elección de tu crédito hasta la firma de tus escrituras. <span style="font-weight:bold;">¡Nuestro servicio no tiene costo!</span></p>
            
              <p class="descripcion-producto"><span style="font-weight:bold;">*Importante:</span> Las condiciones presentadas son <span style="font-weight:bold;">informativas</span> y <span style="font-weight:bold;">referenciales</span>, por lo que, éstas prodrían cambiar de acuerdo con el análisis de tu perfil y los parametros del banco.</p>
            </div>
            <div class="d-md-flex justify-content-md-center" id="icono"></div>
            <div class="row">
              <div class="d-md-flex justify-content-md-center"><h1 style="color: #00368C;"></h1></div>
            </div>
          </div>
        </div>
      </div>
  </div> 
    </div>
</template>
<script>
import vSelect from 'vue-select'
import Productos from '@/views/CasoInmobiliaria/Productos.vue'
import Favoritos from '@/views/Caso/Favoritos.vue'
import Solicitados from '@/views/Caso/Solicitados.vue'
import 'swiper/dist/css/swiper.min.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import Adquisicion from '@/views/Destinos/Adquisicion.vue'
import Mejora from '@/views/Destinos/Mejora.vue'
import AdquisicionCofi from '@/views/Destinos/AdquisicionCofi.vue'
import AdquisicionAliados from '@/views/Destinos/AdquisicionAliados.vue'
import Terreno from '@/views/Destinos/Terreno.vue'
import TerrenoConstruccion from '@/views/Destinos/TerrenoConstruccion.vue'
import TerrenoProgramas from '@/views/Destinos/TerrenoProgrmas.vue'
import TheNavbarVerticalInmobiliaria from '../../layouts/components/navbar/TheNavbarVerticalInmobiliaria.vue'

export default {
  components: {
    vSelect,
    Productos,
    Favoritos,
    swiper,
    swiperSlide,
    Adquisicion,
    Mejora,
    AdquisicionCofi,
    AdquisicionAliados,
    Terreno,
    TerrenoConstruccion,
    Solicitados,
    TerrenoProgramas,
    TheNavbarVerticalInmobiliaria
  },
  data(){
		return{
      swiperOption: {
        pagination: {
          el: '.swiper-pagination'
        }
      },
      socData:'',
      urlSolicitud:'',
      popupSolicitud:false,
      items:[],
      key:'',
			estatus:[],
			destinos:[],
      programas:[],
      id:'',
      caso:{
        valorInmueble:'',
        saldoInsoluto:'',
        plazos:[],
        destino:'',
        esquemas:[],
        pagoMensualActual:'',
        plazoRestante:'',
        ejecutivo:'',
        estado:'',
        estatus:'',
        favoritos:[],
        solicitudes:[],
        montoSolicitado:'',
        programa:'',
        email:'',
        bancos:[],
        inmuebleNuevo:true,
        estado:'',
        plaza:'',
        solicitante:'',
        tasa:'',
        ssv:'',
        creditoInf:'',
        gastosNotarial:3,
      },
      esquemas:[
        { label: 'Pago Fijo',  value: '2' },
        { label: 'Pago creciente',  value: '1' },
      ],
      plazos:[
        { label: '20 Años',  value: '20' },
        { label: '15 Años',  value: '15' },
        { label: '10 Años',  value: '10' },
        { label: '5 Años',  value: '5' },
        { label: '19 Años',  value: '19' },
        { label: '18 Años',  value: '18' },
        { label: '17 Años',  value: '17' },
        { label: '16 Años',  value: '16' },
        { label: '14 Años',  value: '14' },
        { label: '13 Años',  value: '13' },
        { label: '12 Años',  value: '12' },
        { label: '11 Años',  value: '11' },
        { label: '9 Años',  value: '9' },
        { label: '8 Años',  value: '8' },
        { label: '7 Años',  value: '7' },
        { label: '6 Años',  value: '6' },
        { label: '4 Años',  value: '4' },
        { label: '3 Años',  value: '3' },
        { label: '2 Años',  value: '2' },
        { label: '1 Años',  value: '1' },
      ],
      ejecutivos:[],
      bancosEjecutivo:[],
      bancos:[],
      filtroModel:1,
      filtros:[
        { label: 'Mensualidad',  value: '1' },
        { label: 'Cat',  value: '2' },
        { label: 'Tasa',  value: '3' },
        { label: 'Pago total',  value: '4' },
        { label: 'Con promociones',  value: '5' },
       // { label: 'Ingreso Mínimo',  value: '6' },
      ],
      filtrosComplet:[
        { label: 'Mensualidad',  value: '1' },
        { label: 'Cat',  value: '2' },
        { label: 'Tasa',  value: '3' },
        { label: 'Pago total',  value: '4' },
        { label: 'Con promociones',  value: '5' },
        { label: 'Ingreso Mínimo',  value: '6' },
        { label: 'Mayor ahorro total',  value: '7' },
        { label: 'Mayor ahorro mensual',  value: '8' },
      ],
      estados:[],
      plazas:[],
      tasas:[],
      destino:'',
      filtroName:'',
      filtroId:'',
      ingreso:{},
      otro:0,
      s:0,
      o:0,
      nombreSolicitante:'',
      enganche:0,
      bancoSeleccionadp:'',
      mensualidadPromedio:0,
      tasa:0,
      gastosNotariales:0,
      ingresoMinimo:0,
      catPromedio:0,
      ValorInmuebleKredi:'',
			EngancheKredi:'',
			PorcentajeEngancheKredi:'',
			PlazosKredi:'',
			FinanciamientoKredi:'',
			ComisionAperturaKredi:'',
		    EstadoKredi:'',
			GastosNotarialesKredi:''
		}
  },
	mounted(){
    this.$vs.loading({
        container: '#cotizacion',
        scale: 0.6
      })
    this.id=this.$route.params.id
    this.socData=localStorage.getItem('socData')
    this.getApikey()
    //this.getDestinos()
		//this.getEstatus()
    this.dameCaso()
    this.cotiza()
  },
  watch: {
  },
  computed:{
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
    destinoNombreSolo:function(){
      for (var i = 0; i < this.destinos.length; i++) {
        if (this.destinos[i].Id==this.destino) {
          return this.destinos[i].Nombre
        }
      }
    },
  },
	methods:{
    print(){
      var prtContent = document.getElementById("print");
      var WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
      WinPrint.document.write(prtContent.innerHTML);
      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
      /*const modal = document.getElementById("modalTabla")
      const cloned = modal.cloneNode(true)
      let section = document.getElementById("print")

      if (!section) {
        section  = document.createElement("div")
        section.id = "print"
        document.body.appendChild(section)
      }

      section.innerHTML = "";
      section.appendChild(cloned);
      window.print();*/
      /*var is_chrome = Boolean(window.chrome);
      if (is_chrome) {
        setTimeout(function () {
            window.print();
            window.close();
        }, 200);
      }
      else {
          window.print();
          window.close();
      }*/
    },
     numberFormat(number){
      return number.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
     pruebaIon(plazo){
     /* product i.	Adquisición de vivienda = REAL_STATE
      ii.	Liquidación = LIQUIDITY
      iii.	Oficinas y locales = OFFICES_AND_STORES
      iv.	Adquisición de terreno = LAND
      */
     let self= this
     let destino=''
     let GastosInicialCalculado= ''
     let NombreProducto=''
     let respuestaData=''
     let calculoAforo=''

     calculoAforo= (this.caso.montoSolicitado.replace(/,/g,"")*100)/this.caso.valorInmueble.replace(/,/g,"")

      switch(this.destino) {
        case 1:
          destino='REAL_ESTATE'
          GastosInicialCalculado= this.caso.valorInmueble.replace(/,/g,"")*0.08
          NombreProducto='ION Adquisición Plus'
          break;
        case 3:
          destino= 'LIQUIDITY' 
          GastosInicialCalculado= this.caso.valorInmueble.replace(/,/g,"")*0.03
          NombreProducto='ION Liquidez Plus'
          break;
        case 5:
          destino=  'LAND'
          GastosInicialCalculado= this.caso.valorInmueble.replace(/,/g,"")*0.08
          NombreProducto='ION Adquisición de Terreno Plus'
          break;
        case 37:
          destino = 'OFFICES_AND_STORES'
          GastosInicialCalculado= this.caso.valorInmueble.replace(/,/g,"")*0.08
          NombreProducto='ION Oficinas y Locales Plus'
          break;  
      }
           var objGeneraCodigo={
             
              person_type: "NATURAL",//valor fijo
              product: destino,//producto
              required_amount: { "amount": this.caso.montoSolicitado.replace(/,/g,""), "currency": "MXN" },// cantidad requerida
              monthly_income: { "amount": 50000000, "currency": "MXN" },//ingreso mensual valor fijo
              real_estate_value: { "amount": this.caso.valorInmueble.replace(/,/g,""), "currency": "MXN" },//valor inmobiliario
              term: parseInt(plazo)
           } 
        this.$axios.post('https://cotizador.socasesores.com/ApiION/MethodoIon.php',objGeneraCodigo,{headers : {'Content-Type':'application/json; charset=UTF-8'}})
        .then(
            response => { 
               if(response.data.intRespuesta == 0){


                 respuestaData = JSON.parse(response.data.objRespuesta)

                /* if(respuestaData.notification.level=='ERROR'){
                    this.$vs.loading.close('#cotizacion > .con-vs-loading')
                 }else*/
                 
                  if(respuestaData.message=='Internal server error'){
                    this.$vs.loading.close('#cotizacion > .con-vs-loading')

                      self.$vs.notify({
                        title:'Internal server error ION',
                        text:error,
                        color:'danger',
                        position:'top-right'
                      })

                 }else{

                    var respuestaIon = JSON.parse(response.data.objRespuesta)
                    let itemsIon = {}
                    let items=[]
                    let cantidadCotiza = items.length 
                    itemsIon.Cat=parseFloat(respuestaIon.data.cat)
                    itemsIon.Mensualidad=parseFloat(respuestaIon.data.monthly_payment.amount)
                    itemsIon.Tasa=parseFloat(respuestaIon.data.interest_rate)
                    itemsIon.IngresoMinimo= parseFloat(respuestaIon.data.minimum_income) // parseFloat(respuestaIon.data.monthly_payment.amount)/33
                    itemsIon.Enganche=parseFloat(this.caso.valorInmueble.replace(/,/g,"")-this.caso.montoSolicitado.replace(/,/g,""))
                    itemsIon.GastosIniciales=parseFloat(respuestaIon.data.initial_expenses) 
                    itemsIon.Aforo=calculoAforo
                    itemsIon.Plazo=respuestaIon.data.term*12
                    itemsIon.PagoTotal=parseFloat(respuestaIon.data.total_payment)
                    itemsIon.MensualidadPromedio=parseFloat(respuestaIon.data.monthly_payment.amount)
                    itemsIon.MensualidadInicial=parseFloat(respuestaIon.data.monthly_payment.amount)
                    itemsIon.BancoId=21
                    itemsIon.Banco='ION'
                    itemsIon.Producto=NombreProducto
                    itemsIon.Esquema=2
                    itemsIon.Logo='LogoIon_130622.png'
                    itemsIon.PdfAmortizacion= respuestaIon.data.amortization_table.file
                    itemsIon.JsonAmortizacion= respuestaIon.data.amortization_table.raw_data
                    itemsIon.IdIon= respuestaIon.data.id
                    this.items.push(itemsIon)
                    //itemsIon.push(parseInt(this.caso.esquemas[i].value))


                 }
              

            
               }
                this.items.sort((a,b) => (a.MensualidadPromedio > b.MensualidadPromedio) ? 1 : ((b.MensualidadPromedio > a.MensualidadPromedio) ? -1 : 0));
                this.$vs.loading.close('#cotizacion > .con-vs-loading')
            }
        ).catch(function (error) {
           self.$vs.notify({
              title:'Ocurrio un errros en apiIon',
              text:error,
              color:'danger',
              position:'top-right'
            })
        })
    },
    quitarFavorito(item){
      let self=this
      let objRequestFavoritoCaso = {
        strApiKey: this.key,
        strMetodo: 'NoFavorito',
        intVariante: item.Id,
        objCaso: {
         Id: this.id,
         IdBanco: item.BancoId,
         IdIon: item.IdIon
        }
      }
      this.$axios.post('/',objRequestFavoritoCaso,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if (response.data.intEstatus == 0) {
            this.dameCaso()
            this.$vs.notify({
              title:`Eliminado exitosamente ${item.Id}`,
              text:`${item.Producto}`,
              position:'top-right',
            })
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en NoFavorito',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },    
    agregarFavorito (item) {
      let self=this
      let objRequestFavoritoCaso = {
        strApiKey: this.key,
        strMetodo: 'Favorito',
        intVariante: item.Id,
        objCaso: {
         Id: this.id,

        IdBanco: item.BancoId,
        IdIon: item.IdIon,
        intCat: item.Cat,
        ValorInmueble : this.caso.valorInmueble.replace(/,/g,""),
        MontoSolicitado: this.caso.montoSolicitado.replace(/,/g,""),
        intMensualidad: item.Mensualidad,
        intTasa: item.Tasa,
        intIngresoMinimo: item.IngresoMinimo,
        intEnganche: item.Enganche,
        intGastosIniciales: item.GastosIniciales,
        intAforo: item.Aforo,
        intPlazo: item.Plazo,
        intPagoTotal: item.PagoTotal,
        intMensualidadPromedio: item.MensualidadPromedio,
        intMensualidadInicial: item.MensualidadInicial,
        intEsquemas: item.Esquemas

        }
      }
      this.$axios.post('/',objRequestFavoritoCaso,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if (response.data.intEstatus == 0) {
            this.dameCaso()
            this.$vs.notify({
              title:'Agregado exitosamente',
              text:`${item.Producto}`,
              position:'top-right',
            })
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en NoFavorito',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },    
    generaFormatoProductoElegido(item){
      let self=this
      var objRequestSolicitaCaso = {
        strApiKey: this.key,
        strMetodo: "GeneraFormatoSimulacion",
        objSolicitud:{
          Id:this.id,
          Banco:item.BancoId,
          Variante:item.Id
        }
      }
      this.$axios.post('/',objRequestSolicitaCaso,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if (response.data.intEstatus == 0) {
            //this.urlSolicitud=response.data.objContenido.Solicitud
            //this.popupSolicitud=true
            this.s=this.id
            this.o=item.Id
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en GeneraSolicitud',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },    
   /* solicitar(item){
      let self=this
      var objRequestSolicitaCaso = {
        strApiKey: this.key,
        strMetodo: "Solicita",
        intVariante: item.Id,
        objCaso: {
      	 Id: this.id
        }
      }
      this.$axios.post('/',objRequestSolicitaCaso,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if (response.data.intEstatus == 0) {
            this.dameCaso()
            //console.log(item)
            this.enganche=this.numberFormat(item.Enganche)
            this.bancoSeleccionadp=item.Banco
            this.mensualidadPromedio= this.numberFormat(item.MensualidadPromedio)
            this.tasa= this.numberFormat(item.Tasa)
            this.gastosNotariales= this.numberFormat(item.GastosNotariales)
            this.ingresoMinimo=this.numberFormat(item.IngresoMinimo)
            this.catPromedio=this.numberFormat(item.Cat)
            $('#modalTabla').modal('show');
            this.generaFormatoProductoElegido(item)

            this.$vs.notify({
              title:`Agregado exitosamente ${item.Id}`,
              text:`${item.Producto}`,
              position:'top-right',
            })
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en Solicita',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    }, */
    solicitar(item){
      
      this.dameCaso()
      this.enganche=this.numberFormat(item.Enganche)
      this.bancoSeleccionadp=item.Banco
      this.mensualidadPromedio= this.numberFormat(item.MensualidadPromedio)
      this.tasa= this.numberFormat(item.Tasa)
      this.gastosNotariales= this.numberFormat(item.GastosNotariales)
      this.ingresoMinimo=this.numberFormat(item.IngresoMinimo)
      this.catPromedio=this.numberFormat(item.Cat)
      $('#modalTabla').modal('show');
      this.generaFormatoProductoElegido(item)

    },
    quitar(item){
      let self=this
      var objRequestSolicitaCaso = {
        strApiKey: this.key,
        strMetodo: "BorrarSolicita",
        intVariante: item.VarianteId,
        objCaso:{
          Id:this.id,
        }
      }
      this.$axios.post('/',objRequestSolicitaCaso,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if (response.data.intEstatus == 0) {
            this.$vs.notify({
              title:'Eliminado exitosamente',
              position:'top-right',
            })
            this.dameCaso()
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en BorrarSolicita',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    openFavs(){
      this.RegistraLog()
      window.open('https://cotizador.socasesores.com/app/imprimirInmoniliaria/'+this.id);
    },
    filtrar(tipo){
      switch(tipo) {
        case '1':
          this.items.sort((a,b) => (a.MensualidadInicial > b.MensualidadInicial) ? 1 : ((b.MensualidadInicial > a.MensualidadInicial) ? -1 : 0));
          this.filtroName= 'Mensualidad'
          this.filtroId=1
          break;
        case '2':
          this.items.sort((a,b) => (a.Cat > b.Cat) ? 1 : ((b.Cat > a.Cat) ? -1 : 0));
          this.filtroName= 'Cat'
          this.filtroId=2
          break;
        case '3':
          this.items.sort((a,b) => (a.Tasa > b.Tasa) ? 1 : ((b.Tasa > a.Tasa) ? -1 : 0));
          this.filtroName= 'Tasa'
          this.filtroId=3
          break;
        case '4':
          this.items.sort((a,b) => (a.PagoTotal > b.PagoTotal) ? 1 : ((b.PagoTotal > a.PagoTotal) ? -1 : 0));
          this.filtroName= 'Pago Total'
          this.filtroId=4
          break;
        case '5':
          this.items.sort((a,b) => (a.vctPromociones.length < b.vctPromociones.length) ? 1 : ((b.vctPromociones.length < a.vctPromociones.length) ? -1 : 0));
          this.filtroName= 'Promociones'
          this.filtroId=5
          break;
        case '6':
          this.items.sort((a,b) => (a.IngresoMinimo > b.IngresoMinimo) ? 1 : ((b.IngresoMinimo > a.IngresoMinimo) ? -1 : 0));
          this.filtroName= 'Ingreso Mínimo'
          this.filtroId=6
          break;
        case '7':
          this.items.sort((a,b) => (a.AhorroTotal < b.AhorroTotal) ? 1 : ((b.AhorroTotal < a.AhorroTotal) ? -1 : 0));
          this.filtroName= 'Ahorro Total'
          this.filtroId=7
          break;
        case '8':
          this.items.sort((a,b) => (a.MejoraMensualidad < b.MejoraMensualidad) ? 1 : ((b.MejoraMensualidad < a.MejoraMensualidad) ? -1 : 0));
          this.filtroName= 'Mejor Mensualidad'
          this.filtroId=8
          break;
      }
    },
    cotiza(){
      this.$vs.loading({
        container: '#cotizacion',
        scale: 0.6
      })
      let self=this
      this.items=[]

       this.$vs.loading({
        container: '#cotizacion',
        scale: 0.6
      })

      let objRequestCotizaCaso = {
        strApiKey: this.key,
        strMetodo: 'Cotiza',
        objCaso: {
         Id: this.id,
         GastoNotarial: this.caso.gastosNotarial
        }
      }
      this.$axios.post('/',objRequestCotizaCaso,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if (response.data.intEstatus == 0) {

          //Mejora ion
          let bancos=[]
          let esquemas=[]

           for (var i = 0; i < this.caso.bancos.length; i++) {
              if(this.caso.bancos[i].value){
              bancos.push(parseInt(this.caso.bancos[i].value))
              }else{
                bancos.push(parseInt(this.caso.bancos[i]))
              }
            }

            for (var i = 0; i < this.caso.esquemas.length; i++) {
                if(this.caso.esquemas[i].value){
                esquemas.push(parseInt(this.caso.esquemas[i].value))
                }else{
                  esquemas.push(parseInt(this.caso.esquemas[i]))
                }
            }

          for(var j=0; j<this.caso.plazos.length;j++){

             if((this.caso.programa==1 &&  esquemas.includes(2) && bancos.includes(21) && (this.destino==1 || this.destino==3) && (this.caso.plazos[j]==5 || this.caso.plazos[j]==6 || this.caso.plazos[j]==7 || this.caso.plazos[j]==8 || this.caso.plazos[j]==9 || this.caso.plazos[j]==10 || this.caso.plazos[j]==11 || this.caso.plazos[j]==12 || this.caso.plazos[j]==13 || this.caso.plazos[j]==14 || this.caso.plazos[j]==15 || this.caso.plazos[j]==16 || this.caso.plazos[j]==17 || this.caso.plazos[j]==18 || this.caso.plazos[j]==19 || this.caso.plazos[j]==20)) || (this.caso.programa==1 &&  esquemas.includes(2) && bancos.includes(21) && (this.destino==5 || this.destino==37) && (this.caso.plazos[j]==5 || this.caso.plazos[j]==6 || this.caso.plazos[j]==7 || this.caso.plazos[j]==8 || this.caso.plazos[j]==9 || this.caso.plazos[j]==10))){
               
                  console.log(this.caso.plazos)
                  //alert(this.caso.plazos[j]+'-'+j)
                  this.pruebaIon(this.caso.plazos[j])
                
                this.$vs.loading({
                  container: '#cotizacion',
                  scale: 0.6
                })
                this.itemsPrueba=response.data.objContenido 
                var j=0
                for(var i=0; i < this.itemsPrueba.length; i++){
                if( !this.itemsPrueba[i].Producto.includes('7X5')){
                  this.items[j]=this.itemsPrueba[i]
                  j++
                }
                  //alert(this.itemsPrueba[i].Producto)
                }
              // console.log(this.items)
                this.items.sort((a,b) => (a.MensualidadInicial > b.MensualidadInicial) ? 1 : ((b.MensualidadInicial > a.MensualidadInicial) ? -1 : 0));
                this.filtroName= 'Mensualidad'
                this.filtroId=1
                this.$vs.notify({
                  title:`Cotizado y guardado exitosamente`,
                  position:'top-right',
                })
            }else{
            ///////////////////////////////////

                this.itemsPrueba=response.data.objContenido 
                var j=0
                for(var i=0; i < this.itemsPrueba.length; i++){
                if( !this.itemsPrueba[i].Producto.includes('7X5')){
                  this.items[j]=this.itemsPrueba[i]
                  j++
                }
                  //alert(this.itemsPrueba[i].Producto)
                }
              // console.log(this.items)
                this.items.sort((a,b) => (a.MensualidadInicial > b.MensualidadInicial) ? 1 : ((b.MensualidadInicial > a.MensualidadInicial) ? -1 : 0));
                this.filtroName= 'Mensualidad'
                this.filtroId=1
                this.$vs.notify({
                  title:`Cotizado y guardado exitosamente`,
                  position:'top-right',
                })
                this.$vs.loading.close('#cotizacion > .con-vs-loading')
            ///////////////////////////////////
            }
          } //////////for



            for(var j=0; j<this.caso.plazos.length;j++){

              ///inicia apartado de kredi
              if (esquemas.includes(2) && bancos.includes(33) && (this.destino == 1 ) && (this.caso.plazos[j] == 5 || this.caso.plazos[j] == 10 || this.caso.plazos[j] == 15 || this.caso.plazos[j] == 20)){
          
                  	this.ValorInmuebleKredi= parseFloat(this.caso.valorInmueble.replace(/,/g,""))
										this.EngancheKredi= parseFloat(this.caso.valorInmueble.replace(/,/g,"")) - parseFloat(this.caso.montoSolicitado.replace(/,/g,""))
										this.PorcentajeEngancheKredi = parseFloat(this.caso.montoSolicitado.replace(/,/g,"")) / parseFloat(this.caso.valorInmueble.replace(/,/g,"")) * 100
										this.PlazosKredi = this.caso.plazos[j]
										this.FinanciamientoKredi ='No'
										this.ComisionAperturaKredi = '0.0'
										this.EstadoKredi= 20
										this.GastosNotarialesKredi= '0.05'

                    this.RegistraCasoKredi(this.destino,this.ValorInmuebleKredi, this.EngancheKredi, this.PorcentajeEngancheKredi, this.PlazosKredi, this.FinanciamientoKredi, this.ComisionAperturaKredi, this.EstadoKredi, this.GastosNotarialesKredi)

                    this.$vs.loading({
                      container: '#cotizacion',
                      scale: 0.6
                    })

                     this.itemsPrueba=response.data.objContenido 
                      var j=0
                      for(var i=0; i < this.itemsPrueba.length; i++){
                      if( !this.itemsPrueba[i].Producto.includes('7X5')){
                        this.items[j]=this.itemsPrueba[i]
                        j++
                      }

                      }

                      this.items.sort((a,b) => (a.MensualidadInicial > b.MensualidadInicial) ? 1 : ((b.MensualidadInicial > a.MensualidadInicial) ? -1 : 0));
                      this.filtroName= 'Mensualidad'
                      this.filtroId=1

              }
              
            } 
              ////termina apartado de kredi

   
               this.itemsPrueba=response.data.objContenido 
                var j=0
                for(var i=0; i < this.itemsPrueba.length; i++){
                if( !this.itemsPrueba[i].Producto.includes('7X5')){
                  this.items[j]=this.itemsPrueba[i]
                  j++
                }
                  //alert(this.itemsPrueba[i].Producto)
                }
                this.items.sort((a,b) => (a.MensualidadInicial > b.MensualidadInicial) ? 1 : ((b.MensualidadInicial > a.MensualidadInicial) ? -1 : 0));
                this.filtroName= 'Mensualidad'
                this.filtroId=1
                this.$vs.notify({
                  title:`Cotizado y guardado exitosamente`,
                  position:'top-right',
                })
                this.$vs.loading.close('#cotizacion > .con-vs-loading')


          }else {
            this.$vs.notify({
              title:'Ocurrio un error en Cotiza',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
          //this.$vs.loading.close('#cotizacion > .con-vs-loading')
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    	RegistraCasoKredi(destinoId,ValorInmuebleKredi,EngancheKredi,PorcentajeEngancheKredi,PlazosKredi,FinanciamientoKredi,ComisionAperturaKredi,EstadoKredi,GastosNotarialesKredi){
			
			let self = this
			let producto = ''
			let respuestaKredi = ''


			if(destinoId==1){
				producto=1
			}else if(destinoId==19){
				producto=2
			}

			var product_application = {
				product_id: producto,
				answers: {
					"fecha": "26/05/2023",
					"valor_del_inmueble": ValorInmuebleKredi,
					"enganche": EngancheKredi,
					"porcentaje_de_enganche": PorcentajeEngancheKredi,
					"plazo_en_anos": PlazosKredi,
					"financiamiento_de_comision_por_apertura": FinanciamientoKredi,
					"porcentaje_comision_por_apertura": ComisionAperturaKredi,
				
					"estado_en_donde_se_encuentra_la_propiedad": EstadoKredi,
					"porcentaje_gastos_notariales":GastosNotarialesKredi

				},
				"async": false,
				"customer_id": "",
				"external_id": "",
				"dry_run": true
			}
			this.$axios
				.post('https://cotizador.socasesores.com/ApiION/MethodoKredi.php', product_application, { headers: { 'Content-Type': 'application/json; charset=UTF-8' } })
				.then((response) => {

				

					respuestaKredi = JSON.parse(response.data.objRespuesta)
		
					
							let itemsKredi = {}
						let items = []
						let cantidadCotiza = items.length
						itemsKredi.Monto= parseFloat(respuestaKredi.data.attributes.answers.monto_del_credito_solicitado)
						itemsKredi.Cat = parseFloat(respuestaKredi.data.attributes.cat)
						itemsKredi.Mensualidad = parseFloat(respuestaKredi.data.attributes.monthly_payment)
						itemsKredi.Tasa = parseFloat(respuestaKredi.data.attributes.answers.tasa_de_interes)
						itemsKredi.IngresoMinimo = parseFloat(respuestaKredi.data.attributes.minimum_income_required) 
						
						itemsKredi.GastosIniciales = parseFloat(respuestaKredi.data.attributes.answers.valor_avaluo + respuestaKredi.data.attributes.answers.gastos_notariales_aproximados + respuestaKredi.data.attributes.answers.comision_por_apertura) 
						itemsKredi.Avaluo= parseFloat(respuestaKredi.data.attributes.answers.valor_avaluo)
						itemsKredi.ComisionApertura= parseFloat(respuestaKredi.data.attributes.answers.comision_por_apertura)
						itemsKredi.GastosNotariales= parseFloat(respuestaKredi.data.attributes.answers.gastos_notariales_aproximados)
						
						itemsKredi.Aforo = respuestaKredi.data.attributes.answers.aforo
						itemsKredi.Plazo = respuestaKredi.data.attributes.answers.plazo_en_meses
						itemsKredi.PagoTotal = 0
						itemsKredi.MensualidadPromedio = parseFloat(respuestaKredi.data.attributes.monthly_payment)
						itemsKredi.MensualidadInicial = parseFloat(respuestaKredi.data.attributes.monthly_payment)
						itemsKredi.BancoId = 33
						itemsKredi.Banco = 'KREDI'
						if(respuestaKredi.data.attributes.product_id==1){
							itemsKredi.Producto = 'KREDI ADQUISICIÓN DE VIVIENDA'
              itemsKredi.Enganche = parseFloat(respuestaKredi.data.attributes.hitch) 
              
						}else if(respuestaKredi.data.attributes.product_id==2){
							itemsKredi.Producto = 'KREDI MEJORA DE HIPOTECA ORIGEN VIVIENDA'
              itemsKredi.Enganche = 0
              itemsKredi.MejoraMensualidad=0
              itemsKredi.AhorroTotal =0
						}
						
						itemsKredi.Esquema = 2
						itemsKredi.Logo = 'kredi.png'
						//itemsKredi.PdfAmortizacion = respuestaIon.data.amortization_table.file
						itemsKredi.JsonAmortizacion = respuestaKredi.data.attributes.last_amortization_table
						itemsKredi.IdIon = respuestaKredi.data.id
						this.items.push(itemsKredi)

           			

					this.$vs.loading.close('#cotizacion > .con-vs-loading')
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un errros en api KREDI',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},

   
    getApikey(){
      if(!JSON.parse(localStorage.getItem('userInfo')).ApiKey){
        this.$router.push('/login');
      }else {
        this.key=JSON.parse(localStorage.getItem('userInfo')).ApiKey;
      }
    },
    RegistraLog(flag){
      let self=this
      var objRequestRegistraLog = {
        strApiKey: this.key,
        strMetodo: 'RegistraLog',
        objCaso: {
         Id: this.id
        }
      }
      this.$axios.post('/',objRequestRegistraLog,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    dameCaso(flag){
      let self=this
      var objRequestDameCaso = {
        strApiKey: this.key,
        strMetodo: 'DameCaso',
        objCaso: {
         Id: this.id
        }
      }
      this.$axios.post('/',objRequestDameCaso,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            let cotizacion=response.data.objContenido;
            this.caso.plazos=[]
            this.caso.esquemas=[]
             this.caso.bancos=[]
              this.caso.bancos=cotizacion.ComparaBancos
            this.caso.estatus=cotizacion.Estatus
            this.caso.solicitante=cotizacion.Solicitante
            this.destino=cotizacion.Destino
            this.caso.ejecutivo=cotizacion.Broker
            this.caso.email= cotizacion.Solicitante
            this.caso.inmuebleNuevo=cotizacion.Nuevo
            this.caso.plaza=cotizacion.Plaza
            this.caso.estado=cotizacion.EstadoInmueble
            this.caso.tasa=cotizacion.PerfilCliente
            this.caso.gastosNotarial=cotizacion.GastosNotariales
           // this.getBancosEjecutivo(cotizacion.ComparaBancos)
            if(this.destino == 19 || this.destino == 25 || this.destino == 22 || this.destino == 33 || this.destino == 34){
              this.caso.saldoInsoluto=cotizacion.SaldoInsoluto.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              this.caso.pagoMensualActual=cotizacion.PagoMensualActual.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            this.caso.valorInmueble=cotizacion.ValorInmueble.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            for (var i = 0; i < cotizacion.Plazos.length; i++) {
              this.caso.plazos.push(cotizacion.Plazos[i])
            }
            for (var i = 0; i < cotizacion.Esquemas.length; i++) {
              if (cotizacion.Esquemas[i] == 2) {
                this.caso.esquemas.push({ label: 'Pago Fijo',  value: '2' })
              }  else if (cotizacion.Esquemas[i] == 1) {
                this.caso.esquemas.push({ label: 'Pago creciente',  value: '1' })
              }
            }
            this.caso.plazoRestante=cotizacion.PlazoRestante
            this.caso.montoSolicitado=cotizacion.MontoSolicitado.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            this.caso.favoritos=cotizacion.Favoritos
            //console.log(this.caso.favoritos)
            if (flag) {
              for (var i = 0; i < this.caso.favoritos.length; i++) {
                this.quitarFavorito(this.caso.favoritos[i])
              }
            }
            if (cotizacion.Programas.length) {
              this.caso.programa=cotizacion.Programas[0]
            }
            this.caso.solicitudes=cotizacion.Solicitudes
            for (var i = 0; i < this.caso.solicitudes.length; i++) {
              this.caso.solicitudes[i].MensualidadPromedio= this.caso.solicitudes[i].MensualidadPromedio.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              this.caso.solicitudes[i].Cat= this.caso.solicitudes[i].Cat.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            if (this.caso.estado) {
              this.getPlazas()
            }
            if(cotizacion.CreditoInfonavit){
              this.caso.creditoInf=cotizacion.CreditoInfonavit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            if(cotizacion.SubCuentaVivienda){
              this.caso.ssv=cotizacion.SubCuentaVivienda.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            this.dameSolicitante()
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en DameCaso',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    dameSolicitante(){
      let self= this
      var objRequestDameSolicitante = {
        strApiKey: this.key,
        strMetodo: 'DameSolicitante',
        objSolicitante: {
         EMail: this.caso.email
        }
      }
      this.$axios.post('/',objRequestDameSolicitante,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.ingreso=response.data.objContenido.Ingresos[0]
            let solicitante =response.data.objContenido
            this.nombreSolicitante=solicitante.Nombres
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en DameSolicitante',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    comprobarFavorito(item){

      if(item.BancoId==21 && this.caso.favoritos){
 
        for (var i = 0; i < this.caso.favoritos.length; i++) {

          if (this.caso.favoritos[i].BancoId == 21 && this.caso.favoritos[i].Mensualidad == item.Mensualidad && this.caso.favoritos[i].Tasa == item.Tasa) {           
                return false;
                

            }
        }

        
      }else if(item.BancoId==33 && this.caso.favoritos){
 
        for (var i = 0; i < this.caso.favoritos.length; i++) {

          if (this.caso.favoritos[i].BancoId == 33 && this.caso.favoritos[i].Mensualidad == item.Mensualidad && this.caso.favoritos[i].Tasa == item.Tasa) {           
                return false;
                

            }
        }

        
      }else{
         if (this.caso.favoritos) {
            for (var i = 0; i < this.caso.favoritos.length; i++) {
                if (this.caso.favoritos[i].Id == item.Id) {            
                    return false;

                }
            }
          }

      }
      
    
      return true;
    },
    sendCorreoElegido(){
       let self=this
      var objEmail = {
        to:this.caso.solicitante,//this.caso.ejecutivo,
        s: this.s,
        o: this.o,
        apiKey: this.key
      }
      this.$axios.post('https://cotizador.socasesores.com/admin/formatos/emailComparaleProd/SimulacionInmobiliarias.php',objEmail,{headers : {'Content-Type':'application/json; charset=UTF-8'}})
      .then(
        response => {
            //this.$router.push('/login');
            $('#modalTabla').modal('hide');
          this.$vs.notify({
            title:'Se envió correctamente el correo electrónico ',
            
            position:'top-right'
          })
          this.$router.push('/inmobiliaria');

        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })

      
    },
    descargarPDF(){
       $('#modalTabla').modal('hide');
      var s= this.s
       var o=this.o
      location.href= 'https://cotizador.socasesores.com/admin/formatos/SimulacionDestino.php?s='+s+'&o='+o
      this.$router.push('/inmobiliaria');

    }
	}
}
</script>
<style lang="css">
  .v-select ul{
      position: relative !important;
  }
  .filtros{
    display: -webkit-box !important;
    flex-wrap: inherit !important;
  }
  .azulbtn{
    background-color: #1f75fe !important;
  }
    .favoritos{
    border: none !important;
    background: transparent !important;
  }
  .col-md-3.td-modal.margen {
    margin-left: -50px;
}
.descripcion-producto{
  color: #00368C;
  font-size: 10px;
  font-family: museo sans 300;
}
p.descripcion-producto{
  margin-top:20px;
}
.modal-content .modal-productos{
  /*background-image: url('../../assets/images/pages/back-productos.jpg');*/
  background-size: contain;
  padding:40px;
      position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: 0.3rem;
    outline: 0;
    font-weight: 300;
}
.ul-list{
    margin-block-start: 0em !important;
    margin-block-end: 1em !important;
    margin-inline-start: 0px !important;
    margin-inline-end: 0px !important;
    padding-inline-start: 40px !important;
    list-style-type: disc;
    font-weight: bold;
}
.banner-bancos{
  width: 550px;
}

@media screen and (max-width:656px){
  .modal-content .modal-productos{
    padding: 20px;
  }
  .banner-bancos{
    width: 100%;
  }

}
</style>
