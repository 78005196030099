<template>
  <div id="tabla">
    <vx-card class="list-view-item ">
  
        <template slot="no-body">
            <div class="row w-full">
              <div class="alinear2">  
                <!-- IMG COL -->
                <div class="col-md-2">
                    <img :src="'https://cotizador.socasesores.com/uploads/'+ item.Logo" :alt="item.Banco" class="grid-view-img p-4">
                </div>
                <div class="col-md-4">
                        <slot name="item-meta">
                          <vx-tooltip :text="item.Descripcion" position="left" v-if="item.Descripcion">
                            <vs-chip type="gradient">Descripcion</vs-chip>
                          </vx-tooltip>
                          <br>
                          <br>
                            <h6 class="titulo">{{ item.Producto }}</h6>
                            <p class="span-placeholder mb-4">Pago <span class="font-semibold cursor-pointer">{{ item.Esquema==2 ? 'Fijo' : 'Creciente' }}</span> durante <span class="font-semibold cursor-pointer">{{ item.Plazo/12 }} años</span></p>
                            <slot name="mejora"></slot>
                            <p class="item-description span-placeholder">Mensualidad promedio ${{ numberFormat(item.MensualidadPromedio) }}</p>
                            <p class="item-description span-placeholder">Tasa {{ numberFormat(item.Tasa) }}%</p>
                            <p class="item-description span-placeholder">Cat {{ numberFormat(item.Cat) }}%</p>
                        </slot>
                </div> 
                <div class="col-md-4"> 
                  <div class="alinear">  
                    <div class="col-md-2">
                                <button class="favoritos" type="line" @click="tablaAmortizacionPrev(item)">
                                  <svg class="note">
                                    <g id="note">
                                      <path id="Vector" d="M0,0V4.188" transform="translate(11.167 2.792)"  stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                                      <path id="Vector-2" data-name="Vector" d="M0,0V4.188" transform="translate(22.333 2.792)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                                      <path id="Vector-3" data-name="Vector" d="M0,0H11.167" transform="translate(9.771 18.146)"  stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                      <path id="Vector-4" data-name="Vector" d="M0,0H6.979" transform="translate(9.771 23.729)"  stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                      <path id="Vector-5" data-name="Vector" d="M18.146,0c4.648.251,6.979,2.024,6.979,8.584v8.626c0,5.751-1.4,8.626-8.375,8.626H8.375C1.4,25.837,0,22.961,0,17.211V8.584C0,2.024,2.331.265,6.979,0Z" transform="translate(4.188 4.885)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                                    </g>
                                  </svg>  
                                </button>
                    </div>            
                    <div class="col-md-2">            
                              <slot name="action-buttons-2" />
                    </div>
                    <div class="col-md-4" >
                      <slot name="action-buttons" />
                    </div>   
                </div>
                </div>
              </div>
                <!-- ITEM NAME & DESC COL -->
                <div class="">
                    <div class="">


                        <vs-popup fullscreen title="Tabla de amortización" :active.sync="popupActive">
                          <vs-prompt title="Exportar a Excel" class="export-options" cancel-text="Cancelar" @cancle="clearFields" @accept="exportToExcel" accept-text="Exportar" @close="clearFields" :active.sync="activePrompt">
                              <input v-model="fileName" placeholder="Nombre del archivo" class="w-full" />
                              <v-select v-model="selectedFormat" :options="formats" class="my-4" />
                              <div class="flex">
                                <span class="mr-4">Tamaño de la celda automático:</span>
                                <vs-switch v-model="cellAutoWidth">Tamaño automático</vs-switch>
                              </div>
                          </vs-prompt>
                          <vs-table :data="itemsTable" max-items="12"
                            pagination
                            noDataText="No hay datos para mostrar">
                            <template slot="header">
                              <vs-button @click="activePrompt=true;popupActive=false;" class="export" >Exportar a tabla de Excel</vs-button>
                              <vs-button @click="sendEmail(item)" class="export" >Enviar por correo</vs-button>
                            </template>
                            <template slot="thead">
                              <vs-th>
                                Mes
                              </vs-th>
                              <vs-th>
                                Capital
                              </vs-th>
                              <vs-th>
                                Tasa
                              </vs-th>
                              <vs-th>
                                Intereses
                              </vs-th>
                              <vs-th>
                                Amortización
                              </vs-th>
                              <vs-th>
                                Mensualidad
                              </vs-th>
                              <vs-th>
                                Gastos
                              </vs-th>
                              <vs-th>
                                Pago Mensual
                              </vs-th>
                            </template>

                            <template slot-scope="{data}">
                              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                                <vs-td :data="data[indextr].Mes" >
                                  {{data[indextr].Mes}}
                                </vs-td>

                                <vs-td :data="data[indextr].Capital">
                                  $ {{numberFormat(data[indextr].Capital)}}
                                </vs-td>

                                <vs-td :data="data[indextr].Tasa">
                                  {{numberFormat(data[indextr].Tasa)}}%
                                </vs-td>

                                <vs-td :data="data[indextr].Interes">
                                  $ {{numberFormat(data[indextr].Interes)}}
                                </vs-td>
                                <vs-td :data="data[indextr].Amortizacion">
                                  ${{numberFormat(data[indextr].Amortizacion)}}
                                </vs-td>
                                <vs-td :data="data[indextr].Mensualidad">
                                  $ {{numberFormat(data[indextr].Mensualidad)}}
                                </vs-td>
                                <vs-td :data="data[indextr].GastosDiferidos">
                                  $ {{numberFormat(data[indextr].GastosDiferidos)}}
                                </vs-td>
                                <vs-td :data="data[indextr].PagoMensual">
                                  $ {{numberFormat(data[indextr].PagoMensual)}}
                                </vs-td>
                              </vs-tr>
                            </template>
                          </vs-table>
                          <div class="">
                            <h5>Totales</h5>
                            <vs-table :data="itemsTable">
                              <template slot="thead">
                                <vs-th>
                                  Meses Totales
                                </vs-th>
                                <vs-th>
                                  Monto de crédito
                                </vs-th>
                                <vs-th>
                                  Tasa
                                </vs-th>
                                <vs-th>
                                  Suma intereses
                                </vs-th>
                                <vs-th>
                                  Suma amortización
                                </vs-th>
                                <vs-th>
                                  Suma mensualidad
                                </vs-th>
                                <vs-th>
                                  Gastos totales
                                </vs-th>
                                <vs-th v-if="item.BancoId!=21">
                                  Pago Mensual total
                                </vs-th>
                                <vs-th v-if="item.BancoId==21">
                                  Pago total
                                </vs-th>
                              </template>

                              <template>
                                <vs-tr>
                                  <vs-td>
                                    {{mesesTotales}}
                                  </vs-td>
                                  <vs-td>
                                    $ {{numberFormat(amortizacionTotal)}}
                                  </vs-td>
                                  <vs-td>
                                    {{numberFormat(tasa)}}%
                                  </vs-td>
                                  <vs-td>
                                    $ {{numberFormat(interesesTotales)}}
                                  </vs-td>
                                  <vs-td>
                                    $ {{numberFormat(amortizacionTotal)}}
                                  </vs-td>
                                  <vs-td>
                                    $ {{numberFormat(mensualidadTotal)}}
                                  </vs-td>
                                  <vs-td>
                                    $ {{numberFormat(gastosTotales)}}
                                  </vs-td>
                                  <vs-td>
                                    $ {{numberFormat(pagoMensualTotal)}}
                                  </vs-td>
                                </vs-tr>
                              </template>
                            </vs-table>
                          </div>
                        </vs-popup>
                        <!--<vs-collapse open-hover>
                          <vs-collapse-item>
                            <div slot="header">
                              Ver más información
                            </div>
                            <p class="item-description span-placeholder">Ingreso mínimo ${{ numberFormat(item.IngresoMinimo) }}</p>
                            <p class="item-description span-placeholder">Pago Total ${{ numberFormat(item.PagoTotal) }}</p>
                            <p class="item-description span-placeholder">Aforo {{ numberFormat(item.Aforo) }}%</p>
                            <p class="item-description span-placeholder">Mensualidad Inicial ${{ numberFormat(item.MensualidadInicial) }}</p>
                            <slot name="cofinavit"></slot>
                            <slot name="gastos"></slot>
                            <p class="item-description span-placeholder">Enganche ${{ numberFormat(item.Enganche) }}</p>
                          </vs-collapse-item>
                        </vs-collapse>-->
                    </div>
                </div>

                <!-- PURCHASE COL -->
                <!--<div class="vx-col sm:w-1/4 w-full flex items-center sm:border border-0 border-solid d-theme-border-grey-light border-r-0 border-t-0 border-b-0">
                    <div class="p-4 flex flex-col w-full">
                      <vs-row v-if="user && !ingreso" vs-w="12"  class="mb-2">
                          <p class="span-placeholder text-danger">Es necesario capturar ingresos para visualizar el cumplimiento de las condiciones</p>
                      </vs-row>
                      <div class="" v-if="user && ingreso">
                        <vs-row vs-w="12" vs-justify="space-between">
                          <p class="span-placeholder">Cumplimiento {{item.Perfil}}%</p>
                          <vx-tooltip color="primary" :text="'Condiciones incumplidas: '+incumplidas" v-if="item.Perfil < 100">
                            <feather-icon icon="InfoIcon"/>
                          </vx-tooltip>
                        </vs-row>
                        <vs-progress :percent="item.Perfil" color="primary"></vs-progress>
                      </div>
                        <div class="text-warning flex self-end border border-solid border-warning py-1 px-2 rounded" v-if="item.vctPromociones.length">
                          <vx-tooltip color="primary" :text="'PROMOCIÓN: '+promos" v-for="promos in item.vctPromociones" :key="promos">
                            <feather-icon icon="StarIcon" svgClasses="h-4 w-4" />
                          </vx-tooltip>
                        </div>
                        <div class="my-6">
                            <h5 class="text-center" v-if="filtroId!==5">{{filtroName}}</h5>
                            <h5 class="font-bold text-center" v-if="filtroId==1">$ {{ numberFormat(item.MensualidadPromedio) }}</h5>
                            <h5 class="font-bold text-center" v-else-if="filtroId==2"> {{ numberFormat(item.Cat) }} %</h5>
                            <h5 class="font-bold text-center" v-else-if="filtroId==3"> {{ numberFormat(item.Tasa) }} %</h5>
                            <h5 class="font-bold text-center" v-else-if="filtroId==4">$ {{ numberFormat(item.PagoTotal) }}</h5>
                            <h5 class="font-bold text-center" v-else-if="filtroId==6">$ {{ numberFormat(item.IngresoMinimo) }}</h5>
                            <h5 class="font-bold text-center" v-else-if="filtroId==7">$ {{ numberFormat(item.AhorroTotal) }}</h5>
                            <h5 class="font-bold text-center" v-else-if="filtroId==8">$ {{ numberFormat(item.MejoraMensualidad) }}</h5>
                            <h5 class="font-bold text-center" v-else-if="filtroId==5"> {{ item.vctPromociones.length > 0 ? item.vctPromociones.length : 'Sin ' }} promociones</h5>
                        </div>
                        SLOT: ACTION BUTTONS
                    </div>
                    
                </div>-->
            </div>
        </template>
    </vx-card>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import axios from 'axios';
export default{
  components: {
    vSelect
  },
  props: {
    idCaso: {
      type: Number,
      required: false
    },
    item: {
      type: Object,
      required: true
    },
    user: {
      type: String,
      required: false
    },
    filtroName:{
      type: String,
      required: false
    },
    filtroId:{
      type: Number,
      required: false
    },
    ingreso: {
      type: Object,
      required: false
    },
  },
  data(){
		return{
      itemId:'',
      solicitante:'',
      fileName: '',
      formats:['xlsx', 'csv', 'txt'],
      cellAutoWidth: true,
      selectedFormat: 'xlsx',
      headerTitle: ['Mes', 'Capital', 'Tasa', 'Interes', 'Amortizacion','Mensualidad','Gastos','Pago'],
      headerVal: ['Mes', 'Capital', 'Tasa', 'Interes', 'Amortizacion','Mensualidad','GastosDiferidos','PagoMensual'],
      popupActive: false,
      activePrompt: false,
      itemsTable:[],
      interesesTotales:0,
      mensualidadTotal:0,
      mesesTotales:0,
      montoSolicitado:0,
      amortizacionTotal:0,
      gastosTotales:0,
      pagoMensualTotal:0,
      tasa:0,
    }
  },
  mounted(){
    this.id=this.$route.params.id
    this.getApikey()
    if(this.id){
      //this.idCaso=this.id
      this.dameCaso()
    }
  },
  computed:{
    socData(){
      //return this.$store.state.socData
      return JSON.parse(localStorage.getItem('socData'))
    },
    incumplidas:function(){
      if (this.item.vctIncumplidas.length) {
        let text=''
        for (var i = 0; i < this.item.vctIncumplidas.length; i++) {
          let j=i+1
          text=text + ' '+ j +':'+this.item.vctIncumplidas[i]
        }//console.log(text)
        return text
      }
    }
  },
  methods: {
    numberFormat(num){
      return num.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    tablaAmortizacionPrev(item){

     if(item.BancoId==33){
        this.tablaAmorKredi(item) 

      }else{
        this.tablaAmortizacion(item) 
      }
    },
    tablaAmorKredi(item){
      
      let self=this
      if(this.id>0){
        this.idCaso=this.id
      }

      

       this.$vs.loading({
          container: '#tabla',
          scale: 0.6
        })

        var product_application = {
				idTablaAmortizacion: item.JsonAmortizacion,
        plazoKredi: item.Plazo
			
			}
      this.$axios
				.post('https://cotizador.socasesores.com/ApiION/MethodoKredAmortizacion.php', product_application, { headers: { 'Content-Type': 'application/json; charset=UTF-8' } })
				.then((response) => {


          this.interesesTotales=0
            this.mensualidadTotal=0
            this.amortizacionTotal=0
            this.gastosTotales=0
            this.pagoMensualTotal=0
            this.mesesTotales=0
            this.montoSolicitado=0
            this.tasa=0
            this.itemsTable= response.data.objContenido
            this.popupActive = true
            for (var i = 0; i < this.itemsTable.length; i++) {
              this.interesesTotales =this.interesesTotales +this.itemsTable[i].Interes
              this.mensualidadTotal =this.mensualidadTotal +this.itemsTable[i].Mensualidad
              this.amortizacionTotal =this.amortizacionTotal +this.itemsTable[i].Amortizacion
              this.gastosTotales =this.gastosTotales +this.itemsTable[i].GastosDiferidos
              this.pagoMensualTotal =this.pagoMensualTotal +this.itemsTable[i].PagoMensual
              this.tasa=this.itemsTable[i].Tasa
              this.mesesTotales=this.itemsTable[i].Mes
              this.montoSolicitado=this.itemsTable[i].Capital
            }
            this.$vs.loading.close('#tabla > .con-vs-loading')
           

		
					
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un errros en api KREDI',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})


    },
    sendEmail(variant){
      let self=this
      var objEmail = {
        to: this.solicitante,
        case: this.id,
        token:this.key,
        variant:variant.Id,
        variant_name:variant.Producto,
        bank:variant.Banco,
        amortizacionIon: variant.JsonAmortizacion
      }
      axios.post('https://cotizador.socasesores.com/emailComparaleProd/Simulation.php',objEmail,{headers : {'Content-Type':'application/json; charset=UTF-8'}})
      .then(
        //response => {
          this.$vs.notify({
            title:'Se envió correctamente el correo electrónico a',
            text:this.solicitante,
            position:'top-right'
          })
       // }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    dameCaso(){
      let self=this
      var objRequestDameCaso = {
        strApiKey: this.key,
        strMetodo: 'DameCaso',
        objCaso: {
         Id: this.id
        }
      }
      this.$axios.post('/',objRequestDameCaso,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            let cotizacion=response.data.objContenido;
            this.solicitante=cotizacion.Solicitante
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en DameCaso',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getApikey(){
      if(!JSON.parse(localStorage.getItem('userInfo')).ApiKey){
        this.$router.push('/login');
      }else {
        this.key=JSON.parse(localStorage.getItem('userInfo')).ApiKey;
      }
    },
    exportToExcel () {
      import('@/vendor/Export2Excel').then(excel => {
        const list = this.itemsTable
        const data = this.formatJson(this.headerVal, list)
        excel.export_json_to_excel({
          header: this.headerTitle,
          data,
          filename: this.fileName,
          autoWidth: this.cellAutoWidth,
          bookType: this.selectedFormat
        })
        this.clearFields()
      })
    },
    formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        return v[j]
      }))
    },
    clearFields () {
      this.filename = ''
      this.cellAutoWidth = true
      this.selectedFormat = 'xlsx'
    },

    DescargaPdfIon(urlIon){
      let self=this
    
      var objPdf = {
        url: urlIon

      }
      axios.post('https://cotizador.socasesores.com/DescargaPdfIon.php',objPdf,{headers : {'Content-Type':'application/json; charset=UTF-8'}})
      .then(
        response => {
          
          location.href= 'https://cotizador.socasesores.com/uploads/AmortizacionIon/AmortizacionIon.php?objUrl='+JSON.stringify(urlIon)



        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    tablaAmortizacion(item){
      let self=this
      if(this.id>0){
        this.idCaso=this.id
      }

      let TablaPagosIon= ''
      let AmortizacionPdf=''
      let AmortizacionJson=''

      if(item.BancoId==21){
        TablaPagosIon=true;
        AmortizacionPdf= item.PdfAmortizacion
        AmortizacionJson= item.JsonAmortizacion
      }

      this.$vs.loading({
          container: '#tabla',
          scale: 0.6
        })
      let objRequestDameTablaPagos = {
        strApiKey: this.key,
        strMetodo: 'DameTablaPagos',
        objResultado: {
          Id:item.Id,
          caso:this.id,
          caso:this.idCaso,
          IonAmortizacion:TablaPagosIon,
          pdfIon:AmortizacionPdf,
          jsonIon:AmortizacionJson,
          plazo: item.Plazo,
          tasa: item.Tasa
        }
      }
      this.$axios.post('/',objRequestDameTablaPagos,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus != 0){
            this.$vs.notify({
              title:'Ocurrio un error en DameTablaPagos',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }else {
            if(item.BancoId==21){
              // this.DescargaPdfIon(response.data.objContenido)
              // this.$vs.loading.close('#tabla > .con-vs-loading')

                this.interesesTotales=0
                this.mensualidadTotal=0
                this.amortizacionTotal=0
                this.gastosTotales=0
                this.pagoMensualTotal=0
                this.mesesTotales=0
                this.montoSolicitado=0
                this.tasa=0
                this.itemsTable= response.data.objContenido
                this.popupActive = true
                for (var i = 0; i < this.itemsTable.length; i++) {
                  this.interesesTotales =this.interesesTotales +this.itemsTable[i].Interes
                  this.mensualidadTotal =this.mensualidadTotal +this.itemsTable[i].Mensualidad
                  this.amortizacionTotal =this.amortizacionTotal +this.itemsTable[i].Amortizacion
                  this.gastosTotales =this.gastosTotales +this.itemsTable[i].GastosDiferidos
                  this.pagoMensualTotal =this.pagoMensualTotal +this.itemsTable[i].PagoMensual
                  this.tasa=this.itemsTable[i].Tasa
                  this.mesesTotales=this.itemsTable[i].Mes
                  this.montoSolicitado=this.itemsTable[i].Capital
                }
                this.$vs.loading.close('#tabla > .con-vs-loading')
             
            }else{

            this.interesesTotales=0
            this.mensualidadTotal=0
            this.amortizacionTotal=0
            this.gastosTotales=0
            this.pagoMensualTotal=0
            this.mesesTotales=0
            this.montoSolicitado=0
            this.tasa=0
            this.itemsTable= response.data.objContenido
            this.popupActive = true
            for (var i = 0; i < this.itemsTable.length; i++) {
              this.interesesTotales =this.interesesTotales +this.itemsTable[i].Interes
              this.mensualidadTotal =this.mensualidadTotal +this.itemsTable[i].Mensualidad
              this.amortizacionTotal =this.amortizacionTotal +this.itemsTable[i].Amortizacion
              this.gastosTotales =this.gastosTotales +this.itemsTable[i].GastosDiferidos
              this.pagoMensualTotal =this.pagoMensualTotal +this.itemsTable[i].PagoMensual
              this.tasa=this.itemsTable[i].Tasa
              this.mesesTotales=this.itemsTable[i].Mes
              this.montoSolicitado=this.itemsTable[i].Capital
            }
            this.$vs.loading.close('#tabla > .con-vs-loading')
          }



          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.alinear2{
  display: flex;
  align-items: center;
  border-bottom: #E0E0E0 solid 1px;  
}
.vx-card{
  margin-bottom: 0px;
}
.list-view-item {
    .item-name, .item-description {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .item-name {
        -webkit-line-clamp: 2;
    }

    .item-description {
        -webkit-line-clamp: 5;
    }

    .grid-view-img {
        max-width: 100%;
        max-height: 100%;
        width: auto;
        transition: .35s;
    }
/*
    &:hover {
        transform: translateY(-5px);
        box-shadow: 0px 4px 25px 0px rgba(0,0,0,.25);

        .grid-view-img{
            opacity: 0.9;
        }
    }*/
}
.export{
    margin-bottom: 3%;
    float: right;
}
@media screen and (max-width:915px) {
  .alinear2{
    padding: 20px;
    border-radius: 10px;
  border: #E0E0E0 solid 1px;  
  display: block;
  align-items: center;
}
h6.titulo {
    font-size: 14px;
}
  
}
</style>
